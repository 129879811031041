import * as React from "react";
import styled from "styled-components"
import { AdSense, AffiliateItemComponent, Seo, TagComponent, TitleComponent } from "../components/atoms";
import { TagListComponent } from "../components/molecules";
import { LayoutComponent } from "../components/organisms";

const Post = styled.div``

const Meta = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`

const Content = styled.div`
  a {
    color: #061f5c;
  }

  img {
    height: auto;
    max-width: 100%;
  }

  blockquote {
    background-color: #f5f5f5;
    border-left: 12px solid #dbdbdb;
    font-style: italic;
    margin: 0;
    padding: 1rem;
  }

  .gatsby-highlight {
    max-width: 100%;

    pre {
      overflow: auto;
    }
  }
`

const PostPage = ({ pageContext }) => {
  const post = pageContext.post
  const tags = post.tags.map(tag => <TagComponent key={tag} tag={tag} />)
  let affiliateItem = null
  if (post.affiliateItem !== null) {
    if (post.affiliateItem.affiliateItem !== null) {
      affiliateItem = <AffiliateItemComponent item={post.affiliateItem.affiliateItem} />
    }
  }

  return (
    <LayoutComponent hasTitle={true}>
      <Post>
        <TitleComponent title={post.title} href={post.canonical} />
        <Meta>
          <div>{post.publishedAt}</div>
          <TagListComponent>{tags}</TagListComponent>
        </Meta>
        <AdSense slot="7156079388" format="auto" />
        <Content
          dangerouslySetInnerHTML={{
            __html: post.body.childMarkdownRemark.html,
          }}
        ></Content>
        {affiliateItem}
      </Post>
    </LayoutComponent>
  )
}

export default PostPage
export const Head = ({ pageContext }) => (
  <Seo title={pageContext.post.title}
    description={pageContext.post.body.childMarkdownRemark.excerpt}
    pathname={ pageContext.post.canonical } />
)
